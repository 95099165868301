<template>
    <div class="demo-header-feed-wrapper">
        <div class="container-fluid demo-header-feed-wrapper-body">
            <div class="demo-header-feed-wrapper-body-logo">
                <div class="desktop-logo">
                        <a href="https://blog.wearitoutapp.com" target="_blank">
                            <img :src="require('@/assets/images/general/wear-it-logo.svg')">
                        </a>
                    </div>
                    <div class="mobile-logo">
                        <a href="https://blog.wearitoutapp.com" target="_blank">
                            <img :src="require('@/assets/images/general/logo-colored.svg')">
                        </a>
                    </div>
            </div>
            <div class="demo-header-feed-wrapper-body-actions">
                <router-link :to="{ name: 'Login' }" class="btn-default">LOG IN</router-link>
                <router-link :to="{ name: 'SignUpEmail' }" class="btn-default ml-3">SIGN UP</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
