<template>
  <div class="popup-white email-subscription">
    <div class="popup-white-in">
      <img @click="close" class="close" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABq0lEQVR4nO2WS0oDQRCGv73BlQY1EVGDeheNax8n0MSFxIu4U/EAEo2rGO+hoHgLTdzEZBhp+ANNZ5xHj0EX+WCg6aqu6q7pqmqYMiUby0AdeARegU99ZtwBakCZCVACLoEBECZ8AdAEVn7L+S7QlfE+cAPsAZvAjD4z3pesL12zpprX+YlOFOpUqynWrAF3VjTML/M+eQAMgVOP9Q2tDXwiUbLC7uPc3oSx8QEsZll4bYU9Ly3ZMpc4daoNdZmi/vkRUIyYL0rmsi5bA0U2kbp2bG60y7Fkz84mipoLpePSjJGN0ZGySSsX29FoE1FzLoeSt0nBm5Q3fpC7DpOcozoRqmIm0pNyIUZnHniyqp8xvBCjX5CesZ1I13MDcWk2a6Xj//8Fnb++hLUJpmFUnRijrKLRV2PJW4gqWQuR4Uo7Nl0tL/eydZFl0ZKVDaah+HImG+8JaRpJ1WrHDU/no3a8jSd160HSUmNJomKFPdClzsWOCsjoSWZu9AGwpWJV0Nik2i3wZYXd++Quc8B5ykfpQL0/0wMkLSWl2gPwotre07gtWepUmzLF8A3lSrGRZtYFBAAAAABJRU5ErkJggg==">
      <div class="popup-white-in-content">
        <div class="popup-white-in-content-header">
          <img :src="require('@/assets/images/general/wear-it-logo.svg')">
        </div>
        <div class="popup-white-in-content-body">
          <div class="text-center">
            <h2 class="font-weight-bold">{{ __t('email-subscription.title') }}</h2>
          </div>
          <div v-if="isFormSubmit" class="text-center signup-success">
            <img src="@/assets/images/general/WIO_Sticker_SLAYYY_w-bg.png" class="mb-2"/>
            <div class="yellow-button">
                <button type="submit" class="btn-default">
                  {{ __t('email-subscription.signed-in') }}
                </button>
              </div>
          </div>
          <form v-if="!isFormSubmit">
              <div class="form-group">
                  <label for="username" class="font-weight-bold">Email: *</label>
                  <input
                  :placeholder="__t('email-subscription.email-placeholder')"
                  type="email"
                  class="form-control"
                  required
                  :class="{ 'is-invalid': !validate.email }"
                  v-model="email" id="email" aria-describedby="email"/>
                  <span class="text-danger" v-show="isEmailAlreadyRegister">You are already subscribed!</span>
              </div>
              <div class="form-group">
                <label for="subscriptionTerms">
                  <input type="checkbox" v-model="terms" value="true" id="subscriptionTerms"/>
                    <span :class="{'text-danger': !validate.terms}"> &nbsp; by continuing you agree to our
                      <router-link class="text-underline" :to="{ name: 'Page', params: { slug: 'terms' }}">
                         privacy statement
                      </router-link>
                    </span>
                </label>
              </div>
              <div class="yellow-button">
                <button type="submit" class="btn-default" @click.prevent="subscribe">
                  {{ __t('email-subscription.subscribe') }}
                </button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isFormSubmit: false,
      isEmailAlreadyRegister: false,
      email: '',
      terms: false,
      valid: false,
      validate: {
        email: true,
        terms: true
      }
    }
  },
  methods: {
    ...mapActions('feedback', ['storeSubscriptionEmail']),
    subscribe () {
      if (!this.validateForm()) return
      this.storeSubscriptionEmail({ 'email': this.email })
        .then(response => {
          if (response) {
            this.isFormSubmit = true
            setTimeout(() => {
              this.close()
            }, 2000)
          }
        }).catch(err => {
          let response = err.response
          if (response.status === 422) {
            this.isEmailAlreadyRegister = true
            setTimeout(() => {
              this.close()
            }, 1500)
          }
        })
    },
    close () {
      this.$cookie.set('emailSubscriptionPopup', false)
      this.$emit('toggleClose', 'hide')
    },
    validateEmail (email, s = null) {
      // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validateForm () {
      let errorCount = 0
      if (!this.validateEmail(this.email)) {
        this.validate.email = false
        errorCount++
      }
      if (!this.terms) {
        errorCount++
        this.validate.terms = false
      }
      return !(errorCount > 0)
      // this.valid = this.validate.email && this.validate.terms
    },
    clearTo () {
      clearTimeout(this.settimeout)
    }
  }
}
</script>
