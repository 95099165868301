<template>
  <div id="innerApp">
    <Header></Header>
    <main>
      <div class="view-wrapper feed demo">
        <div class="feed-wrapper" :class="{ 'no-results' : (!feed || feed.length === 0) && !showFeed}">
          <div class="feed-wrapper-in">
            <!-- <div class="feed-wrapper-in-demo-sticker">
              <img :src="require('@/assets/images/general/demo-sticker.png')">
            </div> -->
            <transition name="fade-feed">
              <no-outfits
                v-if="!loading && (!feed || feed.length === 0) && !showFeed"
                :isDemoOutfit="true"
                @addOutfit="handleOutfit">
              </no-outfits>
            </transition>
            <transition name="fade-feed">
              <demo-feed
                v-if="!loading && showFeed && feed && feed.user !== 'undefined'"
                :feed="feed"
                @handleNewFeed="getDemoFeed"
                @handleMore="handleMore"
                @handleRate="handleRating"
                @reloadOutfit="toggleReloadOutfit"
                @fetchNewFeed="handleFetchNewFeed"
                @saveOutfit="toggleSaveOutfit">
              </demo-feed>
            </transition>
            <transition name="fade-feed">
              <Loader v-if="loading"/>
            </transition>
          </div>
        </div>
      </div>
      <email-subscription-popup v-if="showEmailSubscriptionPopup" @toggleClose="toggleEmailSubscriptionPopup"></email-subscription-popup>
    </main>
  </div>
</template>

<script>
import Header from '../../components/feed/demo/Header'
import { mapActions, mapState } from 'vuex'
import DemoFeed from '@/components/feed/demo/DemoFeed'
import Loader from '@/components/icons/loader'
import NoOutfits from '@/components/outfit/NoOutfits'
import EmailSubscriptionPopup from '../../components/modals/EmailSubscriptionPopup'
export default {
  metaInfo: {
    title: 'App',
    meta: [
      {
        name: 'description',
        content: 'Let our inclusive community help you decide what to wear! Express your personal style and share your looks on the app'
      }
      // Add other meta tags as needed
    ]
  },
  components: {
    Header,
    DemoFeed,
    Loader,
    NoOutfits,
    EmailSubscriptionPopup
  },
  data () {
    return {
      showFeed: false,
      loading: true,
      imageCache: null,
      noOutfits: true,
      showEmailSubscriptionPopup: true
    }
  },
  computed: {
    ...mapState('demoOutfit', ['feed', 'phantom_outfit_ids'])
  },
  methods: {
    ...mapActions('demoOutfit', ['getDemoFeed', 'rateFeed']),
    getFeed () {
      this.loading = true
      this.imageCache = []
      this.getDemoFeed(this.getUserId()).then((resp) => {
        // debugger
        for (let i = 0; i < resp.outfit.outfit_items.length; i++) {
          let a = new Image()
          a.src = resp.baseFilePath + resp.outfit.outfit_items[i].image_path
          this.imageCache.push(a)
        }
        this.showFeed = resp
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }).catch(() => {
        this.showFeed = false
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    },
    handleMore (type) {
      type === 1
        ? this.hideFeed({ batch_id: this.feed.id }).then(resp => {
          this.getFeed()
        })
        : this.reportFeed({ batch_id: this.feed.id }).then(resp => {
          this.getFeed()
        })
    },
    handleRating (rate, item) {
      this.rateFeed({ rating: parseInt(rate), outfit_item_id: item.id, user_id: this.getUserId() }).then(resp => {
        if (!resp) this.showFeed = false
      })
    },
    toggleReloadOutfit () {
      if (this.outfitReloadCount > 1) {
        this.handleMore(1)
      } else {
        this.outfitReloadCount++
        this.getOutfit(this.feed.id)
      }
    },
    toggleEmailSubscriptionPopup (status) {
      switch (status) {
        case 'hide':
          this.showEmailSubscriptionPopup = false
          break
      }
    },
    toggleSaveOutfit () {
      this.toggleFavourite()
    },
    getUserId () {
      let userId = this.$cookie.get('userId')
      if (!userId) {
        const timestamp = new Date().getTime()
        userId = Math.floor(Math.random() * timestamp)
        this.$cookie.set('userId', userId)
      }
      return userId
    },
    handleFetchNewFeed () {
      this.getFeed()
    },
    handleOutfit () {
      // code here
    }
  },
  created () {
    this.getFeed()
  },
  mounted () {
    let emailSubscriptionPopup = this.$cookie.get('emailSubscriptionPopup')
    if (emailSubscriptionPopup === 'false') { this.showEmailSubscriptionPopup = false }
  }
}
</script>
