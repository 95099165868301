<template>
  <div class="medium-container theFeed">
    <div class="feed-wrapper-in-wrap">
      <div class="feed-wrapper-in-wrap-head">
        <div class="feed-wrapper-in-wrap-head-profile">
          <div class="feed-wrapper-in-head-profile-img">
            <img :src="feed.user.profile_image" :class="{'stylelist-profile-border': feed.phantom_outfit && feed.phantom_outfit.is_deadline_deactivated}" width="100%" alt=""/>
          </div>
          <div class="feed-wrapper-in-wrap-head-profile-content">
            <h5 class="h5-style-lg" v-text="feed.user.username"></h5>
            <span v-text="feed.user.subline"></span>
          </div>
        </div>
        <div class="feed-wrapper-in-wrap-head-settings">
          <div>
            <img v-if="feed.phantom_outfit && feed.phantom_outfit.is_deadline_deactivated" :src="require('@/assets/images/general/style-expert.png')" width="60px">
            <button class="btn" @click="more = !more">
              <img :src="require('@/assets/images/icons/dots.svg')" width="100%" alt=""/>
            </button>
          </div>
          <div class="window-feed" v-if="more">
            <ul>
              <li><a class="report" @click.prevent="$emit('handleMore', 0); more = false">{{ __t('feed.report') }}</a>
              </li>
              <li><a class="hide" @click.prevent="$emit('handleMore', 1); more = false">{{ __t('feed.hide') }}</a></li>
              <li><a class="go-back" @click.prevent="more = false">{{ __t('feed.go-back-button') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="feed-wrapper-in-wrap-body">
        <div class="feed-wrapper-in-wrap-body-img" :class="{'stylelist-border': feed.phantom_outfit && feed.phantom_outfit.is_deadline_deactivated}" :style="{backgroundColor: getOutfitItemBackgroundColor}">
        <swiper ref="feedSlider" class="swiper noSwipingClass swiper-demo" :options="sliderOptions" @slideChange="slideChange">
            <swiper-slide v-for="(item, i) in feed.outfit_items" :key="'feed-s-key-' + i">
              <template v-if="item.hasOwnProperty('isImageFound') && item.isImageFound === false">
                  <reload-outfit :outfitItem="item" @reloadOutfit="reloadOutfit"></reload-outfit>
              </template>
              <template v-else>
                <div class="outfit-img" @click="handleNextSlide(i)">
                    <img :src="$store.state.demoOutfit.baseFilePath + item.image_path + '?'+imageRefreshCount" width="100%" :key="i" alt="" @error="imageNotFound(item)"/>
                    <template v-if="feed.user.settings_save_outfit || (feed.phantom_outfit && feed.phantom_outfit.added_to_favourite)">
                      <a class="feed-wrapper-in-wrap-body-img-save" href="#" @click.prevent="saveOutfit">
                        <template v-if="feed.is_favourite">
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.8267 27.7467C16.3734 27.9067 15.6267 27.9067 15.1734 27.7467C11.3067 26.4267 2.66675 20.92 2.66675 11.5867C2.66675 7.46666 5.98675 4.13333 10.0801 4.13333C12.5067 4.13333 14.6534 5.30666 16.0001 7.12C17.3467 5.30666 19.5068 4.13333 21.9201 4.13333C26.0134 4.13333 29.3334 7.46666 29.3334 11.5867C29.3334 20.92 20.6934 26.4267 16.8267 27.7467Z" fill="#FFB7F8" stroke="#FFB7F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </template>
                        <template v-else>
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.8266 27.7467C16.3733 27.9067 15.6266 27.9067 15.1733 27.7467C11.3066 26.4267 2.66663 20.92 2.66663 11.5867C2.66663 7.46666 5.98663 4.13333 10.08 4.13333C12.5066 4.13333 14.6533 5.30666 16 7.12C17.3466 5.30666 19.5066 4.13333 21.92 4.13333C26.0133 4.13333 29.3333 7.46666 29.3333 11.5867C29.3333 20.92 20.6933 26.4267 16.8266 27.7467Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </template>
                      </a>
                    </template>
                    <icon-rate
                    v-if="item && item.hasRating"
                    :color="(item.currentUserRating == 5 ? 'FFFD0B' : (item.currentUserRating == 3 ? '8ED6F4' : 'FFB7F8'))"
                    :type="(item.currentUserRating == 5 ? 'yes' : (item.currentUserRating  == 3 ? 'maybe' : 'no'))"></icon-rate>
                </div>
              </template>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="feed-wrapper-in-wrap-body-stickers">
          <template v-for="(item, i) in $store.state.demoOutfit.outfitItemRatings">
            <a :key="'ratings-key-' + i"
               :class="(i == 5 ? 'yes' : (i == 3 ? 'maybe' : 'no'))"
               @click.prevent="handleItemRate(item)">
              <icon-rate
                v-if="feed.outfit_items[currentIndex] && !feed.outfit_items[currentIndex].hasRating"
                :color="'211F2F'"
                :type="(item.value == 5 ? 'yes' : (item.value == 3 ? 'maybe' : 'no'))"></icon-rate>
            </a>
          </template>
        </div>
      </div>
      <div class="feed-wrapper-in-wrap-footer">
        <div class="feed-wrapper-in-wrap-footer-left">
          <div class="feed-wrapper-in-wrap-footer-left-title">
            <h5 class="h5-style" v-text="feed.title"></h5>
          </div>
          <div class="feed-wrapper-in-wrap-footer-left-content">
            <p v-text="feed.description"></p>
            <div class="tags">
              <a v-if="$store.state.demoOutfit.time[feed.time]" v-text="$store.state.demoOutfit.time[feed.time]"
                 class="tag"></a>
              <a v-for="(item, i) in feed.outfit_styles" :key="'feed-os-key-' + i" v-text="item.name" class="tag"></a>
              <a v-for="(item, i) in feed.dress_codes" :key="'feed-dc-key-' + i" v-text="item.name" class="tag"></a>
              <a v-for="(item, i) in feed.event_types" :key="'feed-ev-key-' + i" v-text="item.name" class="tag"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import IconRate from '@/components/icons/icon-rate'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ReloadOutfit from '../../outfit/ReloadOutfit'
import { mapActions } from 'vuex'

export default {
  props: {
    feed: {
      type: Object,
      default: null,
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    IconRate,
    ReloadOutfit
  },
  data () {
    return {
      more: false,
      currentIndex: 0,
      sliderOptions: {
        slidesPerView: 'auto',
        autoHeight: true,
        // allowTouchMove: false,
        // slideToClickedSlide: true,
        watchSlidesProgress: true,
        ovbserver: true,
        observeSlideChildren: true,
        observeParent: true,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          renderBullet (index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
          }
        }
      },
      pendingInterval: null,
      imageRefreshCount: 0
    }
  },
  computed: {
    swiper () {
      return this.$refs.feedSlider.$swiper
    },
    getOutfitItemBackgroundColor () {
      return this.feed.outfit_items[this.currentIndex].color
    }
  },
  methods: {
    ...mapActions('outfit', ['getOutfit']),
    handlePending (datetime) {
      let newDate = new Date()
      let current = new Date(datetime)
      let timing = Math.round(((current.getTime() - newDate.getTime()) / 1000) / 60)
      if (timing < 1) {
        timing = 1
      }
      return timing
    },
    handleItemRate (item) {
      this.$emit('handleRate', item.value, this.feed.outfit_items[this.currentIndex])
      this.feed.outfit_items[this.currentIndex]['currentUserRating'] = item.value
      this.feed.outfit_items[this.currentIndex]['hasRating'] = true
      this.$forceUpdate()
      setTimeout(() => {
        this.moveSlideNext()
      }, 1000)
    },
    slideChange () {
      this.currentIndex = this.swiper.realIndex
    },
    handleNextSlide (i) {
      !this.swiper.isEnd ? this.swiper.slideNext() : this.swiper.slideTo(0)
      // this.swiper.isEnd ? this.swiper.slidePrev() : (this.swiper.isBeginning ? this.swiper.this.swiper.slideNext() : '')
    },
    imageNotFound (item) {
      this.feed.outfit_items = this.feed.outfit_items.map(item => {
        return { ...item, isImageFound: false }
      })
      this.$forceUpdate()
    },
    reloadOutfit () {
      this.$emit('reloadOutfit')
    },
    moveSlideNext () {
      let nextSlideIndex = -1
      for (let i = 0; i < this.feed.outfit_items.length; i++) {
        if (!Object.hasOwn(this.feed.outfit_items[i], 'hasRating')) {
          nextSlideIndex = i
          break
        }
      }
      nextSlideIndex > -1 ? this.swiper.slideTo(nextSlideIndex) : this.$emit('fetchNewFeed')
    },
    saveOutfit (e) {
      this.$emit('saveOutfit')
      e.stopPropagation()
    }
  },
  watch: {
    feed (nv, ov) {
      this.imageRefreshCount++
      nv.id !== ov.id && this.swiper.slideTo(0)
    }
  },
  created () {
    this.handlePending(this.feed.deadline) < 0 && this.$emit('handleNewFeed')
  },
  mounted () {
    this.pendingInterval = setInterval(() => {
      this.handlePending(this.feed.deadline)
    }, 45000)
  },
  beforeDestroy () {
    clearInterval(this.pendingInterval)
  }
}
</script>
